import React, { useContext, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ConfigContext } from '../../modules/config/context'
import ModalRateLimiting from '../../components/Modal/RateLimiting'
import { getServiceRoute } from '../../modules/router/service'
import { SentrySeverity } from '../../modules/sentry/model'

const RETURN_URL_PARAM_NAME = 'returnUrl'

const RateLimiting: React.FC = () => {
  const [params] = useSearchParams()
  const config = useContext(ConfigContext)
  const navigate = useNavigate()

  if (!params.get(RETURN_URL_PARAM_NAME)) {
    Sentry.captureMessage('Rate-limiting: Does not have return url!', SentrySeverity.Warning) // todo remove logging if it repeats frequently

    useEffect(() => {
      navigate(getServiceRoute(config.service, config.country))
    }, [])

    return null
  }

  return (
    <ModalRateLimiting
      callback={() => {
        window.location.href = params.get(RETURN_URL_PARAM_NAME) as string
      }}
    />
  )
}

export default RateLimiting
