import React from 'react'
import { useTranslation } from 'react-i18next'
import { Images } from '../Images'
import { ErrorDiv } from './styled'

const ErrorBoundaryScreen: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ErrorDiv data-testid="error-boundary-screen">
      <div className="logo">
        <a href="/">
          <Images.LogoHome />
        </a>
      </div>
      <div>{t('components.ErrorBoundaryScreen.title')}</div>
    </ErrorDiv>
  )
}

export default ErrorBoundaryScreen
