import styled from 'styled-components'
import mixins from '../../constants/GlobalStyleMixins'

export const ErrorDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  .logo {
    margin-bottom: 20px;

    svg {
      ${mixins.logoHome}
      margin-bottom: 20px;
      color: ${({ theme }) => theme.color.text};
    }
  }
`
