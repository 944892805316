import React from 'react'
import { Trans } from 'react-i18next'
import Icon from '../../Icons'
import { List, Info } from './styled'

interface Props {
  searchQuery: string
}

const ListEnd: React.FC<Props> = ({ searchQuery }) => (
  <List>
    <Info data-testid="fileList-no-results">
      <Icon.SearchNoResults />
      <Trans i18nKey={'components.File.FileListEnd.empty'}>
        No result found for query <strong>{searchQuery}</strong>.
      </Trans>
    </Info>
  </List>
)

export default ListEnd
