export const isBot = () => {
  const ua = window.navigator.userAgent
  const isBot = /bot|google web preview|bingpreview|crawler|slurp|baiduspider/i.test(ua)
  return isBot
}

export const isMobileSafari = () => {
  const ua = window.navigator.userAgent
  const isMobileSafari = /iPhone|iPad|iPod/i.test(ua) && /AppleWebKit/i.test(ua) && !/CriOS/i.test(ua)
  return isMobileSafari
}
