import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Images } from '../../components/Images'
import { getServiceRoute } from '../../modules/router/service'
import { ConfigContext } from '../../modules/config/context'
import { NotFound } from './styled'

const FileNotFound: React.FC = () => {
  const { t } = useTranslation()
  const config = useContext(ConfigContext)

  return (
    <NotFound>
      <div className="logo">
        <Link to={getServiceRoute(config.service, config.country)} reloadDocument={true}>
          <Images.LogoHome />
        </Link>
      </div>
      <div>{t('routes.FileNotFound.title')}</div>
    </NotFound>
  )
}

export default FileNotFound
