import styled from 'styled-components'

export const Loading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    top: calc(50% - 20px - 2px);
    left: calc(50% - 20px - 2px);
    width: 20px;
    height: 20px;
    border: 2px solid currentColor;
    border-top-color: transparent;
    animation: AnimateLoading 0.5s infinite linear;
    border-radius: 50%;
    content: '';
    pointer-events: none;
  }
`

export const ListWrapper = styled.div`
  position: relative;
  display: block;
`

export const Powered = styled.div<{
  $compensationWidth: number
}>`
  position: fixed;
  z-index: 50;
  bottom: 0;
  left: 0;
  width: ${({ $compensationWidth }) => ($compensationWidth > 0 ? 'calc(100% - ' + $compensationWidth + 'px)' : 100 + '%')};
  margin: 0 auto;
  pointer-events: none;
  text-align: right;

  span {
    display: inline-block;
    padding: 2px 5px;
    background-color: ${({ theme }) => theme.color.bg.shade5};
    font-size: 10px;
  }
`
